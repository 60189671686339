<template>
  <div>
    <div class="personnelImproved">
      <!-- <div class="left_one">
        <div class="title">数据概览</div>
        <div class="icon"><i class="iconfont icon-bumen"
             style="color: rgba(75,179,255,1);"></i>
          <div class="cellDiv">
            <p class="cellP"
               style="">运动队数</p>
            <p><span class="cellSpan"
                    style="">{{total.departmentNum}}</span>&nbsp;&nbsp;<span class="num">个</span></p>
          </div>
        </div>
        <div class="icon">
          <i class="iconfont icon-renyuanzongshu"
             style="color: rgba(255,169,54,1);"></i>
          <div class="cellDiv">
            <p class="cellP"
               style="">人员总数</p>
            <p><span class="cellSpan"
                    style="">{{total.staffNum}}</span>&nbsp;&nbsp;<span class="num">人</span></p>
          </div>
        </div>
      </div> -->
      <div class="department">
        <div id="department_num">
        </div>
        <p>运动队数：{{departemtnNum}}个</p>
      </div>
      <div id="jobs_num"></div>
      <!-- <div id="sex_distribution"></div> -->
      <div id="degree_distribution"></div>
      <div id="age_structure"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'personnelImproved',
  data () {
    return {
      total: {
        departmentNum: '',
        staffNum: ''
      },
      departemtnNum: 0
    }
  },

  methods: {
    getJson () {
      this.$axios
        .get("p/staffView/index")
        .then(response => {
          if (response.data.code === 0) {
            this.total = response.data.data.total
            this.$nextTick(() => {
              this.departmentNum(response.data.data.department)
              this.jobsNum(response.data.data.station)
              // this.sexDistribution(response.data.data.sex)
              this.degreeDistribution(response.data.data.education)
              this.ageStructure(response.data.data.age, response.data.data.sex)
            })
          } else {
            this.$message({
              message: response.data.message,
              type: "error"
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    departmentNum (departmentData) {
      let x = [],
        y = [];
      this.departemtnNum = departmentData.length
      departmentData.forEach((item, index) => {
        x.push(item.name)
        y.push(item.num)
      })
      let myChart = this.$echarts.init(document.getElementById("department_num"));
      let option = {
        title: {
          text: "运动队统计",
          x: "center",
          padding: 25,
          textStyle: {
            //标题内容的样式
            color: "#333333", //京东红
            fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
            fontSize: 14 //主题文字字体大小，默认为18px
          }
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          data: x,
          type: "category",
          //竖向刻度
          axisTick: {
            show: false
          },
          // 是否显示坐标轴轴线。
          axisLine: { show: true, lineStyle: { color: "black" } },
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: "gray"
          },
          name: "运动队", // 给Y轴加单位
          axisLine: {
            //这是x轴文字颜色
            lineStyle: {
              color: "#F1F1F1"
            }
          },
          axisLabel: {
            interval: 0, //横轴信息全部显示
            textStyle: {
              color: "#666666" //坐标值得具体的颜色
            },
            formatter: function (value) {
              //只显示五个字 其余省略号
              if (!value) return;
              return value.length > 5 ?
                value.substring(0, 5) + "..." :
                value;
            }
          }
        },
        //实现拖拽效果
        dataZoom: [{
          type: "slider",
          realtime: true, //拖动滚动条时是否动态的更新图表数据
          height: 25, //滚动条高度
          start: 0, //滚动条开始位置（共100等份）
          endValue: 4, //结束位置（共100等份）
          show: false //是否显示该工具。
        },
        {
          type: "inside",
          zoomOnMouseWheel: false //触发缩放
        }
        ],

        yAxis: {
          type: "value",
          splitLine: {
            //网格线
            show: false
          },
          //竖向刻度
          axisTick: {
            show: false
          },
          name: "人数/人", // 给Y轴加单位
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: "gray"
          },
          minInterval: 1,
          axisLine: { show: false, lineStyle: { color: "black" } },
          axisLabel: {
            textStyle: {
              color: "#666666" //坐标值得具体的颜色
            }
          }
        },

        grid: {
          // right: '5%', // 与容器右侧的距离
          top: "35%", // 与容器顶部的距离
          bottom: "12%", // 与容器底部的距离
          left: "10%"
        },
        series: [{
          name: "人数",
          data: y,
          type: "line",
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [{
                  //折线图颜色渐变
                  offset: 0,
                  color: "#CCE7FF"
                },
                {
                  offset: 1,
                  color: "#FAFEFF"
                }
                ]
              )
            }
          },
          itemStyle: {
            normal: {
              color: "#4BB3FF" //线颜色
            }
          }
        }]
      };

      myChart.setOption(option);
    },

    jobsNum (jobsData) {
      let stationStaff = [],
        stationStaffName = [],
        cellNum = [],
        sum = null;
      jobsData.forEach(item => {
        sum += item.num;
        stationStaff.push(item.name);
        stationStaffName.push({
          value: item.num,
          name: item.name
        });
        cellNum[item.name] = {
          percentage: item.name,
          num: item.num + "人"
        };
      });
      // jobs_num
      let myChartB = this.$echarts.init(document.getElementById("jobs_num"));
      let optionB = {
        title: {
          text: "岗位类别",
          x: "center",
          padding: 25,
          textStyle: {
            //标题内容的样式
            color: "#333333", //京东红
            fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
            fontSize: 14 //主题文字字体大小，默认为18px
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },

        legend: {
          orient: "vertical",
          icon: "circle",
          radius: "10%",
          height: "auto",
          trigger: "item",
          data: stationStaffName,
          itemHeight: 10,
          formatter: function (name) {
            if (!name) return
            return (
              "{a|" +
              name +
              "  }{b|" +
              (cellNum[name] ? cellNum[name].num : "--") +
              "  }{c|" +
              (cellNum[name] ? cellNum[name].percentage : "--") +
              "}"
            );
          },
          textStyle: {
            rich: {
              a: {
                fontFamily: "MicrosoftYaHei",
                color: "rgba(102, 102, 102, 1)",
                lineHeight: 27,
                width: 80
              },
              b: {
                fontFamily: "MicrosoftYaHei",
                color: "rgba(136,136,136,1)",
                lineHeight: 27,
                textAlign: "right",
                width: 50
              },
              c: {
                fontFamily: "MicrosoftYaHei",
                color: "rgba(51,51,51,1)",
                lineHeight: 27,
                textAlign: "right",
                width: 50
              }
            }
          },
          type: "scroll",
          show: true,
          // right: 40,
          top: 85,
          left: "55%",
          bottom: 30
        },
        //设置边框颜色
        color: ["#4BB3FF", "#1DBB9D", "#FFCD58", "#FF7EA7", "#FC940A"],
        grid: {
          // right: '5%', // 与容器右侧的距离
          top: "35%", // 与容器顶部的距离
          bottom: "12%", // 与容器底部的距离
          left: "10%"
        },
        series: [{
          name: "",
          type: "pie",
          radius: ["35%", "50%"],
          label: {
            normal: {
              show: true,
              position: "center",
              color: "#4c4a4a",
              formatter: "{total|" +
                "总人数" +
                "}" +
                "\n" +
                "{active| " +
                sum +
                "人}",
              rich: {
                total: {
                  fontFamily: "MicrosoftYaHei",
                  color: "rgba(141,141,141,1)",
                  lineHeight: 28
                },
                active: {
                  fontFamily: "MicrosoftYaHei",
                  color: "#333333"
                }
              },
              emphasis: {
                //中间文字显示
                show: true
              }
            }
          },
          center: ["30%", "55%"], //设置饼图位置
          labelLine: {
            normal: {
              show: false
            }
          },
          data: stationStaffName
        }]
      };
      myChartB.setOption(optionB);
      this.$echartsResize(myChartB);
    },

    sexDistribution (sexData) {
      let server = []
      sexData.forEach(item => {
        server.push({
          name: item.sex === 1 ? '男' : '女',
          value: item.num
        })
      })
      // 基于准备好的dom，初始化echarts实例
      var pieChart = this.$echarts.init(document.getElementById('sex_distribution'));
      pieChart.setOption({
        title: {
          text: '性别分布', //标题
          // subtext: '练习专用',  //副标题
          x: 'center', // 位置
          padding: 25,
          textStyle: {
            fontWeight: 0,
            fontSize: 16,
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"//模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。
        },
        color: ['#4BB3FF', '#FC940A'],
        //图例
        legend: {
          //图例垂直排列
          bottom: 0,
          width: 10,
          itemGap: 16, // 设置legend的间距
          itemWidth: 10, // 设置宽度
          itemHeight: 10, // 设置高度
          right: 150, //legend相对位置
          top: 'center', //legend相对位置
          textStyle: { fontSize: "14" }, //legend字体大小
        },
        series: [
          {
            name: '',
            type: 'pie',
            //饼状图
            // radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            //标签
            label: {
              normal: {
                show: true,
                position: 'inside',
                formatter: '{d}%',//模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  align: 'center',
                  fontSize: 12,
                }
              },
            },
            radius: '60%',
            itemStyle: { // 此配置
              normal: {
                borderWidth: 1,
                borderColor: '#ffffff',
              },
              emphasis: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: server
          }
        ]
      })

    },

    degreeDistribution (educationData) {
      let x = [],
        y = []

      educationData.forEach(item => {
        x.push(item.num)
        switch (item.education) {
          case 1:
            y.push('博士')
            break;
          case 2:
            y.push(' 硕士')
            break;
          case 3:
            y.push('本科')
            break;
          case 4:
            y.push('专科')
            break;
          case 5:
            y.push('其他')
            break;
        }
      })

      let myChart = this.$echarts.init(document.getElementById("degree_distribution"));
      let option = {
        title: {
          text: "学历分布",
          x: "center",
          padding: 25,
          textStyle: {
            //标题内容的样式
            color: "#333333", //京东红
            fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
            fontSize: 14 //主题文字字体大小，默认为18px
          }
        },
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: ['2011年', '2012年']
        },
        grid: {
          // right: '5%', // 与容器右侧的距离
          top: "35%", // 与容器顶部的距离
          bottom: "12%", // 与容器底部的距离
          left: "10%"
        },
        xAxis: {
          type: 'value',
          //竖向刻度
          axisTick: {
            show: false
          },
          name: '人数/人',
          // 是否显示坐标轴轴线。
          axisLine: { show: true, lineStyle: { color: "black" } },
          // name: "年龄", // 给Y轴加单位
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: "gray"
          },
          minInterval: 1,
          axisLine: {
            //这是x轴文字颜色
            lineStyle: {
              color: "#F1F1F1"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#666666" //坐标值得具体的颜色
            }
          },
          minInterval: 1
        },
        yAxis: {
          type: 'category',
          data: y,
          axisLabel: { color: "#666666" },
          //刻度
          axisTick: {
            show: false
          },
          // 是否显示坐标轴轴线。
          axisLine: { show: false, lineStyle: { color: "black" } },
          // 是否显示分隔线。默认数值轴显示，类目轴不显示。
          splitLine: {
            show: false
          },
          // name: "人数", // 给Y轴加单位
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: "gray"
          },
          minInterval: 1
        },
        series: [
          {
            // name: '2011年',
            type: 'bar',
            data: x,
            // data: y,
            barWidth: 12,
            itemStyle: {
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [0, 0, 0, 0],
                color: function (params) {
                  return "#4BB3FF";
                }, //以下为是否显示，显示位置和显示格式的设置了
                label: {
                  position: "top",
                  formatter: "{b}\n{c}"
                }
              }
            }
          }
        ]
      };

      myChart.setOption(option);
    },

    ageStructure (ageData, sexData) {
      let x = [],
        y = [];
      ageData.forEach(item => {
        y.push(item.num)
        x.push(item.age)
      })

      let server = []
      sexData.forEach(item => {
        server.push({
          name: item.sex === 1 ? '男' : '女',
          value: item.num
        })
      })

      let myChart = this.$echarts.init(document.getElementById("age_structure"));
      let option = {
        baseOption: {
          timeline: {
            axisType: 'category',
            show: false,
          },

          tooltip: {
          },

          calculable: true,
          grid: {
            // right: '5%', // 与容器右侧的距离
            top: "35%", // 与容器顶部的距离
            bottom: "12%", // 与容器底部的距离
            left: "10%"
          },
          xAxis: [
            {
              'type': 'category',
              'axisLabel': { 'interval': 0 },
              'data': x,
              splitLine: { show: false },
              data: x,
              type: "category",
              //竖向刻度
              axisTick: {
                show: false
              },
              // 是否显示坐标轴轴线。
              axisLine: { show: true, lineStyle: { color: "black" } },
              name: "年龄", // 给Y轴加单位
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: "gray"
              },
              minInterval: 1,
              axisLine: {
                //这是x轴文字颜色
                lineStyle: {
                  color: "#F1F1F1"
                }
              },
              axisLabel: {
                textStyle: {
                  color: "#666666" //坐标值得具体的颜色
                }
              }
            }
          ],
          yAxis: [
            {
              data: x,
              type: "category",
              //竖向刻度
              axisTick: {
                show: false
              },
              // 是否显示坐标轴轴线。
              axisLine: { show: true, lineStyle: { color: "black" } },
              name: "人数/人", // 给Y轴加单位
              nameTextStyle: {
                padding: [0, 40, 0, 0],
                color: "gray"
              },
              minInterval: 1,
              axisLine: {
                //这是x轴文字颜色
                lineStyle: {
                  color: "#F1F1F1"
                }
              },
              axisLabel: {
                textStyle: {
                  color: "#666666" //坐标值得具体的颜色
                  //       }
                  //     }
                }
              }
            }
          ],
          series: [
            { name: '年龄', type: 'bar' },
            {
              name: '男女',
              type: 'pie',
              center: ['75%', '35%'],
              radius: '28%',
              z: 100
            }
          ]
        },
        options: [
          {
            title: {
              text: "年龄及性别结构",
              x: "center",
              padding: 25,
              textStyle: {
                color: "#333333", //京东红
                fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
                fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
                fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
                fontSize: 14 //主题文字字体大小，默认为18px
              }
            },
            color: ['#4BB3FF', '#FC940A'],
            dataZoom: [{
              type: "slider",
              realtime: true, //拖动滚动条时是否动态的更新图表数据
              height: 25, //滚动条高度
              start: 0, //滚动条开始位置（共100等份）
              endValue: 10, //结束位置（共100等份）
              show: false //是否显示该工具。
            },
            {
              type: "inside",
              zoomOnMouseWheel: false //触发缩放
            }
            ],
            series: [
              {
                name: "人数/人",
                type: "bar",
                data: y,
                barWidth: 12,
                itemStyle: {
                  normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [7, 7, 0, 0],
                    color: function (params) {
                      return "#4BB3FF";
                    }, //以下为是否显示，显示位置和显示格式的设置了
                    label: {
                      position: "top",
                      formatter: "{b}\n{c}"
                    }
                  }
                }
              },
              {
                name: '',
                type: 'pie',
                //饼状图
                // radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                //标签
                label: {
                  normal: {
                    show: true,
                    position: 'inside',
                    formatter: '{b}：{d}%',//模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                    textStyle: {
                      align: 'center',
                      fontSize: 12,
                    }
                  },
                },
                itemStyle: { // 此配置
                  normal: {
                    borderWidth: 1,
                    borderColor: '#ffffff',
                  },
                  emphasis: {
                    borderWidth: 0,
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                data: server
              }
            ]
          }
        ]
      };

      myChart.setOption(option);
      this.$echartsResize(myChart);
      // let optionC = {
      //   title: {
      //     text: "按年龄的人数统计",
      //     x: "center",
      //     padding: 25,
      //     textStyle: {
      //       //标题内容的样式
      //       color: "#333333", //京东红
      //       fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
      //       fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
      //       fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
      //       fontSize: 14 //主题文字字体大小，默认为18px
      //     }
      //   },
      //   color: ["#3398DB"],
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       // 坐标轴指示器，坐标轴触发有效
      //       type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
      //     }
      //   },
      //   grid: {
      //     // right: '5%', // 与容器右侧的距离
      //     top: "35%", // 与容器顶部的距离
      //     bottom: "12%", // 与容器底部的距离
      //     left: "10%"
      //   },
      //   //实现拖拽效果
      //   dataZoom: [{
      //     type: "slider",
      //     realtime: true, //拖动滚动条时是否动态的更新图表数据
      //     height: 25, //滚动条高度
      //     start: 0, //滚动条开始位置（共100等份）
      //     endValue: 10, //结束位置（共100等份）
      //     show: false //是否显示该工具。
      //   },
      //   {
      //     type: "inside",
      //     zoomOnMouseWheel: false //触发缩放
      //   }
      //   ],
      //   xAxis: [{
      //     type: "category",
      //     data: x,
      //     type: "category",
      //     //竖向刻度
      //     axisTick: {
      //       show: false
      //     },
      //     // 是否显示坐标轴轴线。
      //     axisLine: { show: true, lineStyle: { color: "black" } },
      //     name: "年龄", // 给Y轴加单位
      //     nameTextStyle: {
      //       padding: [0, 40, 0, 0],
      //       color: "gray"
      //     },
      //     minInterval: 1,
      //     axisLine: {
      //       //这是x轴文字颜色
      //       lineStyle: {
      //         color: "#F1F1F1"
      //       }
      //     },
      //     axisLabel: {
      //       textStyle: {
      //         color: "#666666" //坐标值得具体的颜色
      //       }
      //     }
      //   }],
      //   yAxis: [{
      //     axisLabel: { color: "#666666" },
      //     type: "value",
      //     //刻度
      //     axisTick: {
      //       show: false
      //     },
      //     // 是否显示坐标轴轴线。
      //     axisLine: { show: false, lineStyle: { color: "black" } },
      //     // 是否显示分隔线。默认数值轴显示，类目轴不显示。
      //     splitLine: {
      //       show: false
      //     },
      //     name: "人数", // 给Y轴加单位
      //     nameTextStyle: {
      //       padding: [0, 40, 0, 0],
      //       color: "gray"
      //     },
      //     minInterval: 1
      //   }],
      //   series: [{
      //     name: "人数",
      //     type: "bar",
      //     data: y,
      //     barWidth: 12,
      //     itemStyle: {
      //       normal: {
      //         //柱形图圆角，初始化效果
      //         barBorderRadius: [7, 7, 0, 0],
      //         color: function (params) {
      //           return "#4BB3FF";
      //         }, //以下为是否显示，显示位置和显示格式的设置了
      //         label: {
      //           position: "top",
      //           formatter: "{b}\n{c}"
      //         }
      //       }
      //     }
      //   }, {
      //     name: 'GDP占比',
      //     type: 'pie',
      //     center: ['75%', '35%'],
      //     radius: '28%',
      //     z: 100
      //   }]
      // };

    }
  },

  mounted () {
    this.getJson()
  }

}
</script>

<style lang="scss" scoped>
.department {
  position: relative;
  p {
    position: absolute;
    top: 80px;
    right: 60px;
    z-index: 222;
    // font-size: 16px;
    font-family: ArialMT;
    line-height: 1.770833rem;
    color: #888;
  }
}

#department_num {
  width: 100%;
  height: 100%;
  position: relative;
}

.personnelImproved {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 30px;
  > div {
    width: 49%;
    height: 430px;
    margin-top: 30px;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 4px 11px 0px rgba(233, 233, 233, 0.5);
    border-radius: 4px;
    border: 1px solid rgba(235, 235, 235, 1);
  }

  .left_one {
    display: flex;
    align-content: center;
    position: relative;
    .title {
      position: absolute;
      font-size: 18px;
      color: rgb(51, 51, 51);
      line-height: 26px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      top: 30px;
      left: 50%;
      width: 80px;
      margin-left: -40px;
    }
    > div {
      width: 50%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;
      > i {
        font-size: 55px;
        margin-right: 15px;
      }
      .cellP {
        font-size: 16px;
        font-family: ArialMT;
        line-height: 1.770833rem;
        color: #888;
      }
    }
  }
}

.cellSpan {
  font-size: 25px;
  font-family: MicrosoftYaHei;
  color: #333;
  line-height: 1.09375rem;
  font-weight: 400;
}
.num {
}
</style>
